
// @ts-nocheck
import { Component, Vue, Watch } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import {
  getProductsBySeller,
  getSellersOfProduct,
} from '@/services/seller.service'
import { Product } from '@/interfaces/product.interface'
import { isoDateToDay, isoDateToMonth, isoDateToHour } from '@/helpers/date'
import { ErrorMessages } from '@/helpers/constants'
import { State } from 'vuex-class'
import { getMyData } from '@/services/user.service'

@Component({
  components: {
    SellerHeader,
  },
})
export default class Products extends Vue {
  //@State(({ sellerState }) => sellerState.eventSelected) eventId!: number;
  eventId!: number = localStorage.getItem('selectedEvent')
  public products: Product[] = []
  public productSellers = []

  public viewSellerInProducts = 0
  public productsAutocomplete = {}
  public productsFiltered: Product[] = []
  public filter = ''
  public page = 1
  public totalPages = 1
  public filterByID = ''
  public categoryId = ''

  public filtersSelected = 'all'
  public filtersSelects = [
    {
      text: 'Todos',
      value: 'all',
    },
    {
      text: 'Productos vendidos',
      value: 'sold',
    },
    {
      text: 'Productos disponibles',
      value: 'available',
    },
  ]

  public skeletonCount = [0, 1, 2, 3]
  public loading = false

  public total_products_sold_type = null

  setTicketsQuotaColor(constrain: boolean) {
    if (constrain) {
      return 'text-color-red'
    }
    return 'text-color-blue'
  }
  async getProducts(page?: number) {
    try {
      this.loading = true

      if (this.categoryId !== '') {
        if (localStorage.getItem('ticket-qr-category') != null) {
          const data: any = localStorage.getItem('ticket-qr-category')
          this.categoryId = data
        }

        const { meta, data, configs } = await getProductsBySeller({
          eventId:
            this.eventId != null
              ? this.eventId
              : localStorage.getItem('selectedEvent'),
          categoryId: this.categoryId,
          page,
          limit: 50,
        })
        this.totalPages = Math.ceil(meta.total / 50)
        this.products = data

        this.total_products_sold_type = configs
          ? configs.total_products_sold_type
          : false

        this.productsAutocomplete = this.products.map(function(x) {
          return {
            value: x.event.id,
            text: x.event.name,
          }
          // return [x.event.name];
        })

        this.productsFiltered = this.products
        this.filterByStatus(this.filtersSelected)
        this.loading = false
      }
    } catch (error) {
      console.log(error)
      Vue.$toast.error(ErrorMessages.COMMON)
    }
  }

  dateToMonth(isoDate: string, type: string) {
    if (type === 'day') {
      return isoDateToDay(isoDate)
    }

    return isoDateToMonth(isoDate, true)
  }

  dateToHours(isoDate: string) {
    return isoDateToHour(isoDate)
  }

  goToProductDetail(product: Product) {
    this.$router.push({
      name: 'Detalle del producto',
      params: { productId: product.uuid },
    })
  }

  mounted() {
    this.categoryId = this.$route.params.categoryId

    this.getProducts()
    this.getMyData()
  }

  async getMyData() {
    try {
      const { data } = await getMyData()
      this.viewSellerInProducts = data.business_admin
    } catch (e) {
      console.error(e)
    }
  }

  async getSellersList(id) {
    try {
      const loader = Vue.$loading.show()

      const { data } = await getSellersOfProduct(id)
      this.productSellers = data
      loader.hide()
    } catch (e) {
      console.error(e)
    }
  }

  searchProduct() {
    this.productsFiltered = this.products.filter(
      product =>
        product.event.id.toString().includes(this.filterByID.toString()) &&
        product.name.toLowerCase().includes(this.filter.toLowerCase())
    )
  }
  filterByStatus(filterStatus: string) {
    this.filtersSelected = filterStatus
    this.productsFiltered = this.products.filter(product => {
      if (filterStatus === 'all') {
        return (this.productsFiltered = this.products)
      }

      if (filterStatus === 'sold') {
        if (
          product.total_tickets_active >= product.max_quote ||
          product.sell_simple > 0
        ) {
          return true
        }
      } else {
        if (product.total_tickets_active < product.max_quote) {
          return true
        }
      }
    })
  }
  ocupate(totalTicket: number | null) {
    return totalTicket || 0
  }

  // get productsFilter() {

  //   return this.products.filter(product => {
  //     // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  //     return product.name.indexOf(this.filter) > -1;
  //   });

  // }

  @Watch('page', { immediate: true, deep: true })
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onPageChange(nextPage: number, _currentPage: unknown) {
    this.getProducts(nextPage)
  }
}
